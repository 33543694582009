import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Button from "components/Forms/Button";

import { useMutation } from "@apollo/client";
import { UPDATE_CURRENT_USER } from "gql/mutations/update_current_user.gql";
import toast from "react-hot-toast";
import { TOAST_ARIA_PROPS } from "utils/settings";

const ProfileForm = ({ currentUserData, loading }) => {
  const inputClassNames =
    "w-full sm:w-96 appearance-none py-3 px-5 mb-6 text-sm text-gray-dim border focus:outline-none bg-grey-flat";
  const inputNonErrorClassNames = "border-grey-light focus:border-charcoal";
  const inputErrorClassNames = "border-red-light focus:border-red-light";

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({ defaultValues: currentUserData });

  useEffect(() => {
    reset(currentUserData);
  }, [currentUserData]);

  const [updateCurrentUser, mutationRes] = useMutation(UPDATE_CURRENT_USER, {
    onCompleted: (newData) => {
      if (newData.updateCurrentUser.errors.length) {
        newData.updateCurrentUser.errors.forEach((element) => {
          setError(element.path[1], {
            type: "string",
            error: element.message,
          });
        });
        toast.dismiss();
        toast.error("Please check the fields and try again.", TOAST_ARIA_PROPS);
      } else {
        toast.dismiss();
        toast.success("Profile updated.", TOAST_ARIA_PROPS);
      }
    },
  });

  const onSubmit = (data) => {
    updateCurrentUser({
      variables: {
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
        capacity: data.capacity,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="block mb-1 font-medium" htmlFor="firstName">
        First Name
      </label>
      <input
        id="firstName"
        className={`${
          errors.firstName ? inputErrorClassNames : inputNonErrorClassNames
        } ${inputClassNames}`}
        placeholder="Your first name"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register("firstName", {
          required: true,
        })}
      />

      <label className="block mb-1 font-medium" htmlFor="lastName">
        Last Name
      </label>
      <input
        id="lastName"
        className={`${
          errors.lastName ? inputErrorClassNames : inputNonErrorClassNames
        } ${inputClassNames}`}
        placeholder="Your last name"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register("lastName", {
          required: true,
        })}
      />

      {false && (
        <>
          <div className="flex items-center mb-1 ">
            <label className="font-medium" htmlFor="email">
              Email
            </label>
            {errors.email && errors.email.error && (
              <em className="text-red-light">&nbsp;{errors.email.error}</em>
            )}
          </div>
          <input
            id="email"
            className={`${
              errors.email ? inputErrorClassNames : inputNonErrorClassNames
            } ${inputClassNames}`}
            placeholder="Your email"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register("email", {
              required: true,
            })}
          />
        </>
      )}

      <div className="flex items-center mb-1 ">
        <label className="font-medium" htmlFor="password">
          Password
        </label>
        {errors.password && errors.password.error && (
          <em className="text-red-light">&nbsp;{errors.password.error}</em>
        )}
      </div>
      <input
        id="password"
        type="password"
        className={`${
          errors.password ? inputErrorClassNames : inputNonErrorClassNames
        } ${inputClassNames}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register("password")}
      />

      <div className="flex items-center mb-1 ">
        <label className="font-medium" htmlFor="passwordConfirmation">
          Password Confirmation
        </label>
        {errors.passwordConfirmation && errors.passwordConfirmation.error && (
          <em className="text-red-light">
            &nbsp;{errors.passwordConfirmation.error}
          </em>
        )}
      </div>
      <input
        id="passwordConfirmation"
        type="password"
        className={`${
          errors.passwordConfirmation
            ? inputErrorClassNames
            : inputNonErrorClassNames
        } ${inputClassNames}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register("passwordConfirmation")}
      />

      <div className="block mb-2 font-medium">What do you want to do?</div>
      <label
        className="block mb-1 text-sm cursor-pointer"
        htmlFor="capacityJobseeker"
      >
        <input
          id="capacityJobseeker"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("capacity", { required: true })}
          type="radio"
          value="jobseeker"
          name="capacity"
        />
        &nbsp; I&apos;m a Jobseeker &mdash; I want to{" "}
        <strong>search for jobs</strong>.
      </label>

      <label
        className="block mb-8 cursor-pointer text-sm"
        htmlFor="capacityEmployer"
      >
        <input
          id="capacityEmployer"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("capacity", { required: true })}
          type="radio"
          value="employer"
          name="capacity"
        />
        &nbsp; I&apos;m an Employer &mdash; I want to <strong>post jobs</strong>
        .
      </label>

      <div className="mt-4">
        <Button cta="Update Details" disabled={loading} />
      </div>
    </form>
  );
};

ProfileForm.propTypes = {
  currentUserData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

ProfileForm.defaultProps = {
  currentUserData: {},
};

export default ProfileForm;
