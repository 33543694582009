import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import iconAccountCircleOutline from "images/account-circle-outline.svg";
import iconHamburger from "images/icon-hamburger.svg";

const AccountLinks = ({ capacity, email, admin, unreadApplicationsCount }) => {
  const [showSidebar, setShowSidebar] = useState(true);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const sidebarPositionStyle = showSidebar
    ? `left-0`
    : "-left-52 sm:left-0"; /* Always at left-0 on larger screens */

  const overLayClass = showSidebar
    ? "visible opacity-20"
    : "invisible opacity-0";

  const AdminLinks = () => {
    return (
      <>
        <h2 className="text-gray-500 text-sm mb-2 tracking-wide">Admin</h2>
        <NavLink
          to="/admin"
          exact
          className="navlink flex flex-row"
          activeClassName="font-bold text-black"
        >
          <img
            src={iconAccountCircleOutline}
            className="mr-2"
            width="24"
            height="24"
            alt="Account Icon"
          />
          All Users
        </NavLink>
        <hr className="mt-8 mb-6" />
      </>
    );
  };

  return (
    <>
      <div
        className="mt-4 ml-4 sm:hidden absolute top-0"
        onClick={toggleSidebar}
        onKeyPress={toggleSidebar}
        role="button"
        tabIndex="0"
      >
        <img className="inline-block" src={iconHamburger} alt="Menu" />
      </div>
      <div
        className={`bg-black inset-0 fixed z-10 transition-all duration-500 sm:hidden ${overLayClass}`}
        onClick={toggleSidebar}
        onKeyPress={toggleSidebar}
        role="button"
        tabIndex="0"
        aria-label="Toggle Menu"
      />
      <div
        className={`fixed top-0 left-0 bottom-0 transition-all duration-500 h-screen z-20 flex flex-row ${sidebarPositionStyle}`}
      >
        <div className="w-52 flex flex-col justify-content h-full border-r border-gray-200 bg-gray-50">
          <div className="flex-grow p-4">
            {admin && <AdminLinks />}
            <h2 className="text-gray-500 text-sm mb-2 tracking-wide">
              Account
            </h2>
            <NavLink
              to="/account"
              exact
              className="navlink flex flex-row mb-2"
              activeClassName="font-bold text-black"
            >
              <img
                src={iconAccountCircleOutline}
                className="mr-2"
                width="24"
                height="24"
                alt="Account Icon"
              />
              Profile
            </NavLink>
            {capacity === "employer" && (
              <>
                <NavLink
                  to="/account/jobs"
                  exact
                  className="navlink flex flex-row mb-2"
                  activeClassName="font-bold text-black"
                >
                  <img
                    src={iconAccountCircleOutline}
                    className="mr-2"
                    width="24"
                    height="24"
                    alt="Jobs Icon"
                  />
                  Jobs
                </NavLink>
                <NavLink
                  to="/account/businesses"
                  exact
                  className="navlink flex flex-row mb-2"
                  activeClassName="font-bold text-black"
                >
                  <img
                    src={iconAccountCircleOutline}
                    className="mr-2"
                    width="24"
                    height="24"
                    alt="Jobs Icon"
                  />
                  Businesses
                </NavLink>
                <NavLink
                  to="/account/applications"
                  exact
                  className="navlink flex flex-row mb-2"
                  activeClassName="font-bold text-black"
                >
                  <img
                    src={iconAccountCircleOutline}
                    className="mr-2"
                    width="24"
                    height="24"
                    alt="Jobs Icon"
                  />
                  Applications
                  {unreadApplicationsCount > 0 && (
                    <span className="rounded-full bg-charcoal text-white flex justify-center items-center text-[11px] font-normal w-5 h-5 inline-block ml-2">
                      {unreadApplicationsCount}
                    </span>
                  )}
                </NavLink>
              </>
            )}
          </div>
          <div className="text-sm p-4">
            You are signed in as{" "}
            <span className="font-medium">
              {email} ({capacity})
            </span>
            .
          </div>
        </div>
      </div>
    </>
  );
};

AccountLinks.propTypes = {
  capacity: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  admin: PropTypes.bool,
};

AccountLinks.defaultProps = {
  admin: false,
};

export default AccountLinks;
