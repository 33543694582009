import React from "react";

import Loading from "components/Loading";
import iconCheckmarkGreen from "images/icon-checkmark-green.svg";

const Fetching = ({ complete, copy }) => {
  return (
    <div className="flex items-center">
      {complete && (
        <img
          src={iconCheckmarkGreen}
          className="w-4 h-4 mr-2"
          width="16"
          height="16"
          alt="Checkmark Icon"
        />
      )}
      {!complete && <Loading classNames="!w-4 !h-4 !border-2 mr-2" />}
      <span>{copy}</span>
    </div>
  );
};

export default Fetching;
