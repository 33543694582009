import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { sendData } from "utils/request";

import Button from "components/Forms/Button";
import FacebookLoginButton from "components/FacebookLoginButton";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    sendData(
      "/users/sign_in",
      "POST",
      { user: { email: data.email, password: data.password } },
      (response) => {
        setLoading(false);
        if (response instanceof Error) {
          const { error } = response.response.data;
          setError("password", { type: "string", error });
        } else {
          // setCurrentUser({ id: response.data.id });
          // history.push("/account");
          // setLoggedIn(true);
          window.location.href = "/account"; // full page reload until we figure out how to refresh the CSRF token
        }
      }
    );
  };

  return (
    <div className="flex justify-center">
      <div>
        <form className="mt-12 w-96" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="text-3xl mb-4">Sign in to Beautifuljobs</h1>

          <label htmlFor="email" className="block mb-1 font-medium">
            Email address
          </label>
          <input
            className={`${
              errors.email
                ? "border-red-light focus:border-red-light"
                : "focus:border-charcoal"
            } w-full appearance-none py-3 px-5 mb-6 text-sm text-gray-dim border focus:outline-none`}
            placeholder="Your email"
            autoComplete="new-password"
            id="email"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register("email", {
              required: true,
            })}
          />

          <label htmlFor="password" className="block mb-1 font-medium">
            Password
          </label>
          <input
            type="password"
            className={`${
              errors.password
                ? "border-red-light focus:border-red-light"
                : "focus:border-charcoal"
            } w-full appearance-none py-3 px-5 mb-6 text-sm text-gray-dim border focus:outline-none`}
            placeholder="Your password"
            autoComplete="new-password"
            id="password"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register("password", {
              required: true,
            })}
          />
          {errors.password && errors.password.error && (
            <p className="text-red-light -mt-7 pb-0.5 pt-1.5 text-sm">
              {errors.password.error}
            </p>
          )}

          <div className="mt-4 mb-4">
            <Button cta="Sign In" disabled={loading} />
          </div>
          <p className="text-sm mb-8 flex justify-between">
            <span>
              No account?{" "}
              <Link className="underline" to="/register">
                Sign up for free
              </Link>
              .
            </span>
            <span>
              <Link className="underline" to="/request-reset-password">
                Forgot Password?
              </Link>
            </span>
          </p>
        </form>

        <hr className="my-4 border-t border-grey" />
        <h3 className="text-sm mb-2">Sign in with Social Media</h3>
        <div className="flex flex-row">
          <FacebookLoginButton copy="I'm a Jobseeker" className="mr-3" />
          <FacebookLoginButton
            copy="I'm an Employer"
            passthroughParams={{ capacity: "employer", foo: "bar" }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
