import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { CREATE_STRIPE_CHECKOUT_SESSION } from "gql/mutations/create_stripe_checkout_session.gql";
import Fetching from "components/Fetching";

import iconExclamation from "images/icon-exclamation-mark-red.svg";

const PublishJobPage = () => {
  const { jobSlug } = useParams();
  const timerRef = useRef(null);

  const [mutateFunction, { data, loading, error }] = useMutation(
    CREATE_STRIPE_CHECKOUT_SESSION,
    {
      onCompleted: (returndata) => {
        if (!returndata.createStripeCheckoutSession.errors.length) {
          timerRef.current = setTimeout(
            () =>
              window.location.replace(
                returndata.createStripeCheckoutSession.sessionUrl
              ),
            1500
          );
        }
      },
    }
  );

  useEffect(() => {
    mutateFunction({
      variables: {
        jobSlug,
      },
    });
  }, []);

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current);
  }, []);

  if (error) return `Error! ${error.message}`;

  return (
    <div className="">
      <div className="sm:ml-52 p-4">
        <h1 className="text-xl font-bold mb-2">Publish Job</h1>
        <div>
          {loading && (
            <>
              <Fetching copy="Checking job details" />
            </>
          )}
          {data && data.createStripeCheckoutSession.errors.length > 0 && (
            <>
              <Fetching copy="Checking job details" complete />
              <div className="flex items-start">
                <img
                  src={iconExclamation}
                  className="w-4 h-4 mr-2 mt-1"
                  width="16"
                  height="16"
                  alt="Exclamation Icon"
                />
                <div>
                  {data.createStripeCheckoutSession.errors[0].message}. Return
                  to{" "}
                  <a
                    href="https:///www.beautifuljobs.ie/users/dashboard"
                    className="underline"
                  >
                    your dashboard
                  </a>{" "}
                  and check your job's status.
                </div>
              </div>
            </>
          )}
          {data && data.createStripeCheckoutSession.errors.length === 0 && (
            <>
              <Fetching copy="Checking job details" complete />
              <Fetching copy="Redirecting to secure payments page" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublishJobPage;
