import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const FacebookLoginButton = ({ copy, passthroughParams, className }) => {
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    const csrfToken = (document.head.querySelector("[name~=csrf-token]") || {})
      .content;
    setAuthToken(csrfToken);
  }, []);

  const passthroughParamsQueryString = () => {
    if (passthroughParams.length === 0) return "";
    return `?${Object.keys(passthroughParams)
      .map((key) => {
        return `${key}=${passthroughParams[key]}`;
      })
      .join("&")}`;
  };

  return (
    <form
      method="post"
      action={`/users/auth/facebook${passthroughParamsQueryString()}`}
    >
      <button
        type="submit"
        value="form"
        className={`bg-tan text-white py-1.5 px-3 rounded font-medium ${className}`}
      >
        {copy}
      </button>
      <input type="hidden" name="authenticity_token" value={authToken} />
    </form>
  );
};

FacebookLoginButton.propTypes = {
  copy: PropTypes.string.isRequired,
  passthroughParams: PropTypes.object,
  className: PropTypes.string,
};

FacebookLoginButton.defaultProps = {
  passthroughParams: {},
  className: "",
};

export default FacebookLoginButton;
