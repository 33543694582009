import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import App from "App";

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const attachReact = (element, callback) => {
  const node = document.getElementById(element);
  if (node) {
    callback(node);
  }
};

/* eslint-disable react/jsx-props-no-spreading */
document.addEventListener("DOMContentLoaded", () => {
  attachReact("react-spa-root", (node) => {
    ReactDOM.render(
      <ErrorBoundary>
        <BrowserRouter>
          <App {...JSON.parse(node.getAttribute("data"))} />
        </BrowserRouter>
      </ErrorBoundary>,
      node
    );
  });
});
