import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import MinimalLayout from "layouts/MinimalLayout";
import AccountLayout from "layouts/AccountLayout";
import AccountPage from "pages/account/AccountPage";
import UserJobsPage from "pages/account/UserJobsPage";
import UserBusinessesPage from "pages/account/UserBusinessesPage";
import UserApplicationsPage from "pages/account/UserApplicationsPage";
import HomePage from "pages/HomePage";

const PrivateRoutes = ({ currentUser }) => {
  const PrivateRoute = ({ children: Children, exact, path }) => {
    const ComponentOrRedirect = () => {
      return currentUser ? Children : <Redirect to="/login" />;
    };

    return (
      <>
        <Route exact={exact} path={path} render={ComponentOrRedirect} />
      </>
    );
  };

  PrivateRoute.propTypes = {
    children: PropTypes.node.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    layout: PropTypes.string,
  };

  PrivateRoute.defaultProps = {
    exact: false,
    layout: undefined,
  };

  return (
    <>
      <Switch>
        <Route exact path="/">
          <MinimalLayout>
            <HomePage />
          </MinimalLayout>
        </Route>
        <PrivateRoute exact path="/account">
          <AccountLayout>
            <AccountPage />
          </AccountLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/account/jobs">
          <AccountLayout>
            <UserJobsPage />
          </AccountLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/account/businesses">
          <AccountLayout>
            <UserBusinessesPage />
          </AccountLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/account/applications">
          <AccountLayout>
            <UserApplicationsPage />
          </AccountLayout>
        </PrivateRoute>
      </Switch>
    </>
  );
};

PrivateRoutes.propTypes = {
  currentUser: PropTypes.object,
};
PrivateRoutes.defaultProps = {
  currentUser: undefined,
};

export default PrivateRoutes;
