import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import MinimalLayout from "layouts/MinimalLayout";
import LoginPage from "pages/LoginPage";
import RegisterPage from "pages/RegisterPage";
import ConfirmPage from "pages/ConfirmPage";
import RequestResetPasswordPage from "pages/RequestResetPasswordPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import ResendConfirmationCodePage from "pages/ResendConfirmationCodePage";
import PublishJobPage from "pages/PublishJobPage";
import PublishingJobPage from "pages/PublishingJobPage";
import PublishErrorPage from "pages/PublishErrorPage";

const PublicRoutes = ({ currentUser }) => {
  const PublicRedirectRoute = ({ children: Children, exact, path }) => {
    const ComponentOrRedirect = () => {
      return currentUser ? <Redirect to="/account" /> : Children;
    };

    return <Route exact={exact} path={path} render={ComponentOrRedirect} />;
  };

  PublicRedirectRoute.propTypes = {
    children: PropTypes.node.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
  };

  PublicRedirectRoute.defaultProps = {
    exact: false,
  };

  return (
    <Switch>
      <PublicRedirectRoute exact path="/login">
        <MinimalLayout>
          <LoginPage />
        </MinimalLayout>
      </PublicRedirectRoute>
      <PublicRedirectRoute exact path="/register">
        <MinimalLayout>
          <RegisterPage />
        </MinimalLayout>
      </PublicRedirectRoute>
      <PublicRedirectRoute exact path="/confirm">
        <MinimalLayout>
          <ConfirmPage />
        </MinimalLayout>
      </PublicRedirectRoute>
      <PublicRedirectRoute exact path="/request-reset-password">
        <MinimalLayout>
          <RequestResetPasswordPage />
        </MinimalLayout>
      </PublicRedirectRoute>
      <PublicRedirectRoute exact path="/reset-password">
        <MinimalLayout>
          <ResetPasswordPage />
        </MinimalLayout>
      </PublicRedirectRoute>
      <PublicRedirectRoute exact path="/resend-confirmation-code">
        <MinimalLayout>
          <ResendConfirmationCodePage />
        </MinimalLayout>
      </PublicRedirectRoute>

      <Route exact path="/publish/:jobSlug">
        <MinimalLayout headerLinks={false}>
          <PublishJobPage />
        </MinimalLayout>
      </Route>
      <Route exact path="/publishing/:jobSlug">
        <MinimalLayout headerLinks={false}>
          <PublishingJobPage />
        </MinimalLayout>
      </Route>
      <Route exact path="/publish-error">
        <MinimalLayout headerLinks={false}>
          <PublishErrorPage />
        </MinimalLayout>
      </Route>
    </Switch>
  );
};

PublicRoutes.propTypes = {
  currentUser: PropTypes.object,
};
PublicRoutes.defaultProps = {
  currentUser: undefined,
};
export default PublicRoutes;
