import { gql } from '@apollo/client'

export const UPDATE_JOB_APPLICATION = gql`
  mutation UpdateJobApplication($notes: String, $read: Boolean, $applicationId: Int!) {
    updateJobApplication(input: { notes: $notes, applicationId: $applicationId, read: $read }) {
      application {
        id
        notes
        read
      }
    }
  }
`
