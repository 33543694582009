import React from "react";
import PropTypes from "prop-types";

const SearchBox = ({ placeholder, onChange, className }) => {
  return (
    <input
      type="search"
      placeholder={placeholder}
      onChange={onChange}
      className={`border border-gray-300 p-2 ${className}`}
    />
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SearchBox.defaultProps = {
  placeholder: "Search",
  className: "",
};

export default SearchBox;
