import React from "react";
import PropTypes from "prop-types";
import { formatDistance, addDays } from "date-fns";
import { JOB_LIFE_IN_DAYS } from "constants.js";

const JobsTable = ({ jobs, owner }) => {
  return (
    <table className="w-full mb-4">
      <thead>
        <tr>
          <th className="table-heading">ID</th>
          <th className="table-heading">Title</th>
          {owner && (
            <>
              <th className="table-heading">Business</th>
              <th className="table-heading">Expires</th>
              <th className="table-heading">Status</th>
              <th className="table-heading">Applications</th>
              <th className="table-heading">Views</th>
              <th className="table-heading">Published</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {jobs.map((job) => {
          return (
            <tr key={job.id}>
              <td>{job.id}</td>
              <td>{job.title}</td>
              {owner && (
                <>
                  <td>{job.business.name}</td>
                  <td>
                    {!job.publishedAt && "- -"}
                    {job.publishedAt &&
                      formatDistance(
                        addDays(new Date(job.publishedAt), JOB_LIFE_IN_DAYS),
                        new Date(),
                        {
                          addSuffix: true,
                        }
                      )}
                  </td>
                  <td>{job.status}</td>
                  <td>{job.applicationsCount}</td>
                  <td>{job.views}</td>
                  <td>
                    {!job.publishedAt && "- -"}
                    {job.publishedAt &&
                      formatDistance(new Date(job.publishedAt), new Date(), {
                        addSuffix: true,
                      })}
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

JobsTable.propTypes = {
  jobs: PropTypes.array.isRequired,
  owner: PropTypes.bool,
};

JobsTable.defaultProps = {
  owner: false,
};

export default JobsTable;
