import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import PrivateRoutes from "routes/PrivateRoutes";
import PublicRoutes from "routes/PublicRoutes";
import AdminRoutes from "routes/AdminRoutes";
import { Toaster } from "react-hot-toast";
import { TOAST_OPTIONS } from "utils/settings";
import Masquerade from "components/Masquerade";
import { CURRENT_USER } from "gql/queries/current_user.gql";
import {
  useQuery,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  concat,
} from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";

export default function App({ envVariables }) {
  const isComponentMounted = useRef(false);

  useEffect(() => {
    isComponentMounted.current = true;
  }, []);

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const csrfToken = (document.head.querySelector("[name~=csrf-token]") || {})
      .content;
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "X-CSRF-TOKEN": csrfToken,
      },
    }));

    return forward(operation);
  });

  const httpLink = new HttpLink({
    uri: "/graphql",
    credentials: "include",
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            users: offsetLimitPagination(["search"]),
            jobs: offsetLimitPagination(["search", "owned"]),
          },
        },
      },
    }),
    link: concat(authMiddleware, httpLink),
    connectToDevTools: true /* TODO: remove from PROD */,
  });

  // if (!isComponentMounted.current) return null;

  return (
    <ApolloProvider client={client}>
      <ReactApp />
    </ApolloProvider>
  );
}

App.propTypes = {
  envVariables: PropTypes.object,
};

App.defaultProps = {
  envVariables: {},
};

const ReactApp = () => {
  const { loading, error, data } = useQuery(CURRENT_USER);

  if (loading) return null;
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <PrivateRoutes currentUser={data.currentUser} />
      <PublicRoutes currentUser={data.currentUser} />
      <AdminRoutes currentUser={data.currentUser} />
      <Toaster toastOptions={TOAST_OPTIONS} />
      <Masquerade />
    </>
  );
};
