import { gql } from '@apollo/client'

export const USERS = gql`
  query users($offset: Int, $limit: Int, $search: String) {
    users(offset: $offset, limit: $limit, search: $search) {
      id
      firstName
      lastName
      email
      capacity
      confirmedAt
      unreadApplicationsCount
    }
    }
`
