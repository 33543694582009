import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import iconExclamation from "images/icon-exclamation-mark-red.svg";
import iconCheckmarkGreen from "images/icon-checkmark-green.svg";
import Fetching from "components/Fetching";

const PublishingJobPage = () => {
  const { jobSlug } = useParams();
  const timerRef = useRef(null);

  const GET_JOB = gql`
    query getJob($jobSlug: String!) {
      job(jobSlug: $jobSlug) {
        status
        publishedAt
      }
    }
  `;

  const { loading, error, data, startPolling, stopPolling } = useQuery(
    GET_JOB,
    {
      variables: { jobSlug },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    startPolling(2000);
  }, []);

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (!data || (data.job && !data.job.status)) return;
    if (data && !data.job) {
      stopPolling();
      return;
    }

    if (data.job.status === "published") {
      stopPolling();
      console.info(
        `redirecting back to https://www.beautifuljobs.ie/jobs/${jobSlug}`
      );
      timerRef.current = setTimeout(
        () =>
          window.location.replace(
            `https://www.beautifuljobs.ie/jobs/${jobSlug}`
          ),
        2000
      );
    }
  }, [data]);

  if (error) return `Error! ${error.message}`;

  return (
    <div className="">
      <div className="sm:ml-52 p-4">
        <h1 className="text-xl font-bold mb-2">Publishing Job</h1>
        <div className="mb-4">
          {data && !data.job && (
            <>
              <div className="flex items-start">
                <img
                  src={iconExclamation}
                  className="w-4 h-4 mr-2 mt-1"
                  width="16"
                  height="16"
                  alt="Exclamation Icon"
                />
                <div>
                  Job not found. Return to{" "}
                  <a
                    href="https:///www.beautifuljobs.ie/users/dashboard"
                    className="underline"
                  >
                    your dashboard
                  </a>{" "}
                  and check your job's status.
                </div>
              </div>
            </>
          )}

          {loading ||
            (data && data.job && data.job.status !== "published" && (
              <Fetching copy="Publishing Job" />
            ))}
          {data && data.job && data.job.status === "published" && (
            <>
              <Fetching copy="Publishing job" complete />
              <Fetching copy="Redirecting back to job page" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublishingJobPage;
