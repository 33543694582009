import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import AdminLayout from "layouts/AdminLayout";
import UsersPage from "pages/admin/UsersPage";

const AdminRoutes = ({ currentUser }) => {
  const AdminRoute = ({ children: Children, exact, path }) => {
    const ComponentOrRedirect = () => {
      return currentUser && currentUser.role === "admin" ? (
        Children
      ) : (
        <Redirect to="/" />
      );
    };

    return (
      <>
        <Route exact={exact} path={path} render={ComponentOrRedirect} />
      </>
    );
  };

  AdminRoute.propTypes = {
    children: PropTypes.node.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
  };

  AdminRoute.defaultProps = {
    exact: false,
  };

  return (
    <>
      <Switch>
        <AdminRoute exact path="/admin">
          <AdminLayout>
            <UsersPage />
          </AdminLayout>
        </AdminRoute>
      </Switch>
    </>
  );
};

AdminRoutes.propTypes = {
  currentUser: PropTypes.object,
};
AdminRoutes.defaultProps = {
  currentUser: undefined,
};

export default AdminRoutes;
