import { format, parseISO } from "date-fns";

export const FULL_DATE_FORMAT = "dd/MM/yyyy";

/**
 * @param {string} dateString Date string in UTC format.
 * @returns {string} Formatted date.
 */
export function formatDate(dateString) {
  return format(parseISO(dateString), FULL_DATE_FORMAT);
}
