import React from "react";
import PropTypes from "prop-types";

import Logout from "components/Logout";
import Logo from "images/logo.png";

import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CURRENT_USER } from "gql/queries/current_user.gql";

const MinimalLayout = ({ children, header, headerLinks }) => {
  const { loading, error, data } = useQuery(CURRENT_USER);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      {header && (
        <header className="bg-tan px-4 py-2 flex items-center justify-between">
          <Link to="/">
            <img
              className="inline-block w-60"
              src={Logo}
              alt="Logo"
              width="240"
              height="35"
            />
          </Link>
          {headerLinks && (
            <>
              {data.currentUser && (
                <div className="flex flex-row">
                  {data.currentUser.role === "admin" && (
                    <>
                      <div className="mr-2">
                        <Link className="text-white" to="/admin">
                          Admin
                        </Link>
                      </div>
                      <div className="text-white mr-2">|</div>
                    </>
                  )}

                  <div className="mr-2">
                    <Link className="text-white" to="/account">
                      Account
                    </Link>
                  </div>
                  <div className="text-white mr-2">|</div>
                  <Logout className="" />
                </div>
              )}
              {!data.currentUser && (
                <Link className="text-white" to="/login">
                  Sign In
                </Link>
              )}
            </>
          )}
        </header>
      )}
      <div>{children}</div>
    </div>
  );
};

MinimalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.bool,
  headerLinks: PropTypes.bool,
};

MinimalLayout.defaultProps = {
  header: true,
  headerLinks: true,
};

export default MinimalLayout;
