import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

import iconClose from "assets/images/icon-close.svg";

/* react-modal requires some style rules to handle smooth transition - https://reactcommunity.org/react-modal/styles/transitions */
const Modal = ({
  isOpen,
  onRequestClose,
  children,
  shouldCloseOnOverlayClick,
  onAfterClose,
  className,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterClose={onAfterClose}
      closeTimeoutMS={250}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      autoFocus={false}
      className={`bg-white w-[640px] m-5 h-auto grow-0 shrink sm:mt-8 lg:mb-8 outline-none px-5 pt-5 pb-8 lg:px-12 lg:pt-9 lg:pb-32 relative ${className}`}
      overlayClassName="fixed inset-0 bg-charcoal bg-opacity-90 flex justify-center items-start z-20 overflow-x-scroll"
      ariaHideApp={false}
      appElement={document.getElementById("react-spa-root")}
    >
      <div>
        <div
          className="absolute right-4 top-4"
          onClick={onRequestClose}
          onKeyPress={onRequestClose}
          role="button"
          tabIndex="0"
        >
          <img
            className="inline-block w-6 h-6 cursor-pointer"
            src={iconClose}
            alt="Close Icon"
          />
        </div>
        {children}
      </div>
    </ReactModal>
  );
};
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  onAfterClose: PropTypes.func,
  className: PropTypes.string,
};
Modal.defaultProps = {
  shouldCloseOnOverlayClick: true,
  onAfterClose: undefined,
  className: "",
};

export default Modal;
