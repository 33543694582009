import React, { useEffect, useState } from "react";
import { fetchData } from "utils/request";
import { Link } from "react-router-dom";

const ConfirmPage = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    fetchData(
      `/users/confirmation`,
      { confirmation_token: "eP8xKeYSgWPyDfR7hddP" },
      (response) => {
        setLoading(false);
        if (response instanceof Error) {
          setErrorMessage(response.response.data.email[0]);
        } else {
          // console.info("response", response.data);
        }
      }
    );
  }, []);
  if (loading) {
    return <>Confirming your account..</>;
  }
  if (errorMessage) {
    return <>Your account {errorMessage}.</>;
  }
  return (
    <>
      Your account has been confirmed - you can now{" "}
      <Link className="underline" to="/login">
        sign in
      </Link>
      .
    </>
  );
};

export default ConfirmPage;
