import React from "react";
import PropTypes from "prop-types";

import Logout from "components/Logout";
import AccountLinks from "components/AccountLinks";
import { useQuery } from "@apollo/client";
import { CURRENT_USER } from "gql/queries/current_user.gql";

const AccountLayout = ({ children, header }) => {
  const { loading, error, data } = useQuery(CURRENT_USER);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  if (!data.currentUser) return null;

  return (
    <div>
      {header && (
        <header className="h-14 border-b border-gray flex items-center justify-between">
          <div>
            <div className="flex items-center">
              <div className="text-lg ml-14 sm:ml-56">Your Account</div>
            </div>
          </div>
          <Logout className="mr-4 text-charcoal" />
        </header>
      )}
      <div>
        <div className="">
          <AccountLinks
            capacity={data.currentUser.capacity}
            email={data.currentUser.email}
            admin={data.currentUser.role === "admin"}
            unreadApplicationsCount={data.currentUser.unreadApplicationsCount}
          />
          <div className="sm:ml-52 p-4">
            {React.cloneElement(children, {
              currentUserId: data.currentUser.id,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

AccountLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.bool,
};

AccountLayout.defaultProps = {
  header: true,
};

export default AccountLayout;
