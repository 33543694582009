import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { USERS } from "gql/queries/users.gql";
import { MASQUERADE_AS_USER } from "gql/mutations/masquerade_as_user.gql";
import { CURRENT_USER } from "gql/queries/current_user.gql";

import SearchBox from "components/SearchBox";
import { debounce } from "throttle-debounce";
import Loading from "components/Loading";
import Button from "components/Forms/Button";
import { NUM_PAGINATION_RECORDS } from "constants.js";

import PropTypes from "prop-types";

const UsersList = ({ search }) => {
  const history = useHistory();

  const [masqueradeAsUser] = useMutation(MASQUERADE_AS_USER, {
    onCompleted: (data) => {
      window.localStorage.setItem(
        "masqueradeUser",
        JSON.stringify(data.masqueradeAsUser.user)
      );
      history.push("/account");
    },

    refetchQueries: [{ query: CURRENT_USER }],
  });

  const loginAs = (userId) => {
    masqueradeAsUser({
      variables: {
        userId,
      },
    });
  };

  const { loading, error, data, fetchMore } = useQuery(USERS, {
    variables: {
      offset: 0,
      limit: 50,
      search,
    },
  });

  if (loading) return <Loading />;
  if (error) return error;

  if (data.users.length === 0) {
    return <div>No Users found</div>;
  }

  return (
    <div>
      <table className="w-full mb-4">
        <thead>
          <tr>
            <th className="table-heading">ID</th>
            <th className="table-heading">Name</th>
            <th className="table-heading">Email</th>
            <th className="table-heading">Capacity</th>
          </tr>
        </thead>
        <tbody>
          {data.users.map((user) => {
            return (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>
                  {user.firstName} {user.lastName}
                </td>
                <td>{user.email}</td>
                <td>{user.capacity}</td>
                <td>
                  {user.confirmedAt && (
                    <div
                      onClick={() => {
                        loginAs(user.id);
                      }}
                      onKeyDown={() => {
                        loginAs(user.id);
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Login As
                    </div>
                  )}
                  {!user.confirmedAt && (
                    <em className="text-gray-400">unconfirmed</em>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.users.length % NUM_PAGINATION_RECORDS === 0 && (
        <Button
          cta="Load More Users"
          disabled={loading}
          className="sm:w-auto sm:px-5 bg-gray-100 border border-charcoal text-charcoal"
          onClick={() => {
            fetchMore({
              variables: {
                offset: data.users.length,
              },
            });
          }}
        />
      )}
    </div>
  );
};

UsersList.propTypes = {
  search: PropTypes.string,
};

UsersList.defaultProps = {
  search: undefined,
};

const UsersPage = () => {
  const [search, setSearch] = useState("");

  const debouncedSearch = debounce(1000, (value) => setSearch(value));

  const handleSearch = ({ target: { value } }) => {
    debouncedSearch(value || null);
  };

  return (
    <>
      <SearchBox
        placeholder="Search users by name or email"
        onChange={handleSearch}
        className="w-full sm:w-1/4 block mb-4"
      />
      <UsersList search={search} />
    </>
  );
};

export default UsersPage;
