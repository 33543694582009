import { gql } from '@apollo/client'

export const JOBS = gql`
  query jobs($offset: Int, $limit: Int, $search: String, $status: String, $owned: Boolean) {
    jobs(offset: $offset, limit: $limit, search: $search, status: $status, owned: $owned) {
      id
      title
      city
      state
      country
      status
      createdAt
      publishedAt
      views
      business {
        name
      }
      applicationsCount
    }
    currentUser {
      id
      firstName
      lastName
      email
      capacity
      role
    }
  }
`
