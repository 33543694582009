import React from "react";
import PropTypes from "prop-types";

const Button = ({ className, cta, onClick }) => {
  return (
    <button
      type="submit"
      className={`w-full sm:w-96 bg-tan text-white p-2.5 rounded font-medium ${className}`}
      onClick={onClick}
    >
      {cta}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  cta: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: "",
  onClick: () => {},
};

export default Button;
