export const TOAST_OPTIONS = {
  className: "w-full sm:w-3/5",
  style: {
    alignItems: "flex-start",
    maxWidth: "100%",
    padding: "15px",
    color: "#666",
  },
};

export const TOAST_ARIA_PROPS = {
  ariaProps: {
    style: { justifyContent: "flex-start", marginTop: "-2px" },
    className: "pt-0.5",
  },
};
