import { gql } from '@apollo/client'

export const INCOMING_JOB_APPLICATIONS = gql`
  query incomingJobApplications($offset: Int, $limit: Int, $search: String, $jobId: Int) {
    incomingJobApplications(offset: $offset, limit: $limit, search: $search, jobId: $jobId) {
      id
      createdAt
      coverLetter
      read
      notes
      job {
        id
        title
        business {
          name
        }
      }
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`
