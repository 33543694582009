import axios from "axios";
import { camelizeKeys } from "humps";

const clearwordAxios = axios.create();

/* Intercept response to return camelCase values for the front-end */
clearwordAxios.interceptors.response.use(
  (response) => {
    const camelCasedResponse = {
      ...response,
      data: camelizeKeys(response.data, { deep: true }),
    };

    return camelCasedResponse;
  },
  (error) => {
    const modifiedError = error;
    const camelCasedResponse = {
      ...error.response,
      data: camelizeKeys(error.response.data, { deep: true }),
    };
    modifiedError.response = camelCasedResponse;
    throw modifiedError;
  }
);

const captureError = (err) => {
  if (window.sentryClient) {
    window.sentryClient.captureException(err);
  }
};

/**
 * @param {string} uri
 * @param {Object} params Optional uri paramters
 * @param {Function} [callback] Callback function
 * @return {Promise} The Promise to be fulfilled
 */
const fetchData = (uri, params, callback) => {
  return clearwordAxios
    .get(uri, { params })
    .then((response) => {
      if (callback !== undefined) callback(response);
    })
    .catch((err) => {
      if (err.response || err.request) {
        captureError(err);
        if (callback !== undefined) callback(err);
      } else {
        throw err;
      }
    });
};

/**
 * @param {string} uri
 * @param {string} method HTTP method
 * @param {Object} data Data values
 * @param {Function} callback Callback function
 * @return {Promise} The Promise to be fulfilled
 */
const sendData = (uri, method, data, callback) => {
  const csrfToken = (document.head.querySelector("[name~=csrf-token]") || {})
    .content;
  clearwordAxios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

  return clearwordAxios({
    method,
    url: uri,
    data,
  })
    .then((response) => {
      if (callback !== undefined) callback(response);
    })
    .catch((err) => {
      if (err.response || err.request) {
        captureError(err);
        if (callback !== undefined) callback(err);
      } else {
        throw err;
      }
    });
};

export { fetchData, sendData };
