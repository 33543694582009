import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import NotesForm from "components/Applications/NotesForm";
import DOMPurify from "dompurify";
import { formatDate } from "utils/date";
import { useMutation } from "@apollo/client";
import { UPDATE_JOB_APPLICATION } from "gql/mutations/update_job_application.gql";
import toast from "react-hot-toast";
import { TOAST_ARIA_PROPS } from "utils/settings";
import { CURRENT_USER } from "gql/queries/current_user.gql";

const ApplicationsTable = ({ applications, jobOwner }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  const showCoverLetter = (coverLetter) => {
    const sanitizedText = DOMPurify.sanitize(
      coverLetter.replace(/(?:\r\n|\r|\n)/g, "<br />")
    );
    setModalContent(
      <>
        <h2 className="text-lg font-medium -mt-2">Cover Letter</h2>
        <hr className="my-4 border-t border-grey" />
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitizedText,
          }}
        />
        <hr className="my-4 border-t border-grey" />
      </>
    );
    setModalOpen(true);
  };

  const [mutateFunction, { error }] = useMutation(UPDATE_JOB_APPLICATION, {
    refetchQueries: [{ query: CURRENT_USER }],
  });

  const updateRead = (applicationId, read) => {
    mutateFunction({
      variables: {
        applicationId: parseInt(applicationId, 10),
        read,
      },
    });
  };

  useEffect(() => {
    if (!error) return;

    toast.dismiss();
    toast.error(
      "There was a problem updating this application, try reloading the page.",
      TOAST_ARIA_PROPS
    );
  }, [error]);

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
        onAfterClose={() => {
          setModalContent(<></>);
        }}
      >
        {modalContent}
      </Modal>
      <table className="w-full mb-4">
        <thead>
          <tr>
            <th className="table-heading">ID</th>
            <th className="table-heading">Job</th>
            <th className="table-heading">Applied On</th>
            {jobOwner && <th className="table-heading">From</th>}
            <th className="table-heading">Cover Letter</th>
            {jobOwner && (
              <>
                <th className="table-heading">Read</th>
                <th className="table-heading">Notes</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {applications.map((application) => {
            return (
              <tr key={application.id}>
                <td>{application.id}</td>
                <td>
                  {application.job.title}
                  <br />
                  {application.job.business.name}
                </td>
                <td>{formatDate(application.createdAt)}</td>
                {jobOwner && (
                  <td>
                    {application.user && application.user.firstName}{" "}
                    {application.user && application.user.lastName}
                    {application.user && application.user.email && (
                      <>
                        <br />
                        <a href={`mailto:${application.user.email}`}>
                          {application.user.email}
                        </a>
                      </>
                    )}
                  </td>
                )}
                <td>
                  {application.coverLetter ? (
                    <button
                      type="button"
                      className="cursor-pointer"
                      onClick={() => {
                        showCoverLetter(application.coverLetter);
                      }}
                    >
                      view
                    </button>
                  ) : (
                    <em>none</em>
                  )}
                </td>
                {jobOwner && (
                  <>
                    <td>
                      <input
                        type="checkbox"
                        checked={application.read}
                        onChange={() => {
                          updateRead(application.id, !application.read);
                        }}
                      />
                    </td>
                    <td>
                      <NotesForm
                        notes={application.notes}
                        applicationId={parseInt(application.id, 10)}
                      />
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

ApplicationsTable.propTypes = {
  applications: PropTypes.array.isRequired,
  jobOwner: PropTypes.bool,
};

ApplicationsTable.defaultProps = {
  jobOwner: false,
};

export default ApplicationsTable;
