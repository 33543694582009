import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { sendData } from "utils/request";

import Button from "components/Forms/Button";

const ResetPasswordPage = () => {
  const isSubscribed = true;
  const [loading, setLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const { search } = useLocation();
  let token;

  const inputClassNames =
    "w-full appearance-none py-3 px-5 mb-6 text-sm text-gray-dim border focus:outline-none bg-grey-flat";
  const inputNonErrorClassNames = "border-grey-light focus:border-charcoal";
  const inputErrorClassNames = "border-red-light focus:border-red-light";

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    token = new URLSearchParams(search).get("reset_password_token");
    sendData(
      "/users/password",
      "PUT",
      {
        user: {
          reset_password_token: token,
          password: data.password,
          password_confirmation: data.passwordConfirmation,
        },
      },
      (response) => {
        if (isSubscribed) {
          setLoading(false);
          if (response instanceof Error) {
            const responseErrors = response.response.data.errors;
            if (responseErrors.password) {
              setError("password", {
                type: "string",
                error: `Password ${responseErrors.password[0]}`,
              });
            }
            if (responseErrors.passwordConfirmation) {
              setError("passwordConfirmation", {
                type: "string",
                error: `Password Confirmation ${responseErrors.passwordConfirmation[0]}`,
              });
            }
          } else {
            setIsReset(true);
            setTimeout(() => {
              window.location.href = "/account"; // full page reload
            }, 5000);
          }
        }
      }
    );
  };

  return (
    <div className="flex justify-center">
      <form className="mt-12 w-96" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-3xl mb-4">Change Your Password</h1>
        {isReset && (
          <p className="text-sm">
            Your password has been updated. Signing you in...
          </p>
        )}
        {!isReset && (
          <>
            <label className="block mb-1 font-medium" htmlFor="password">
              New Password
            </label>
            <input
              id="password"
              type="password"
              className={`${
                errors.password ? inputErrorClassNames : inputNonErrorClassNames
              } ${inputClassNames}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register("password", {
                required: true,
              })}
            />
            {errors.password && errors.password.error && (
              <p className="text-red-light -mt-7 pb-0.5 pt-1.5 text-sm">
                {errors.password.error}
              </p>
            )}

            <label
              className="block mb-1 font-medium"
              htmlFor="passwordConfirmation"
            >
              New Password Confirmation
            </label>
            <input
              id="passwordConfirmation"
              type="password"
              className={`${
                errors.passwordConfirmation
                  ? inputErrorClassNames
                  : inputNonErrorClassNames
              } ${inputClassNames}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register("passwordConfirmation", {
                required: true,
              })}
            />
            {errors.passwordConfirmation &&
              errors.passwordConfirmation.error && (
                <p className="text-red-light -mt-7 pb-0.5 pt-1.5 text-sm">
                  {errors.passwordConfirmation.error}
                </p>
              )}

            <div className="mt-4 mb-4">
              <Button cta="Change Password" isabled={loading} />
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default ResetPasswordPage;
