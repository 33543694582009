import React, { useEffect } from "react";
import ProfileForm from "components/ProfileForm";
import { useQuery } from "@apollo/client";
import { CURRENT_USER } from "gql/queries/current_user.gql";

const AccountPage = () => {
  const { loading, error, data } = useQuery(CURRENT_USER);

  useEffect(() => {
    console.info("data", data);
  }, [data]);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <h1 className="text-xl font-bold">Your Profile</h1>
      <p className="text-sm mb-4">Update your user details</p>
      <ProfileForm currentUserData={data.currentUser} loading={loading} />
    </div>
  );
};

export default AccountPage;
