import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { sendData } from "utils/request";
import { Link } from "react-router-dom";

import Button from "components/Forms/Button";

const RegisterPage = () => {
  const [loading, setLoading] = useState(false);

  const inputClassNames =
    "w-full appearance-none py-3 px-5 mb-6 text-sm text-gray-dim border focus:outline-none bg-grey-flat";
  const inputNonErrorClassNames = "border-grey-light focus:border-charcoal";
  const inputErrorClassNames = "border-red-light focus:border-red-light";

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({ defaultValues: { capacity: "jobseeker" } });

  const onSubmit = (data) => {
    setLoading(true);
    sendData(
      "/users",
      "POST",
      {
        user: {
          email: data.email,
          password: data.password,
          password_confirmation: data.passwordConfirmation,
          first_name: data.firstName,
          last_name: data.lastName,
          capacity: data.capacity,
        },
      },
      (response) => {
        setLoading(false);
        if (response instanceof Error) {
          const responseErrors = response.response.data.errors;
          if (responseErrors.email) {
            setError("email", {
              type: "string",
              error: `Email ${responseErrors.email[0]}`,
            });
          }
          if (responseErrors.password) {
            setError("password", {
              type: "string",
              error: `Password ${responseErrors.password[0]}`,
            });
          }
          if (responseErrors.passwordConfirmation) {
            setError("passwordConfirmation", {
              type: "string",
              error: `Password Confirmation ${responseErrors.passwordConfirmation[0]}`,
            });
          }
        } else {
          // We want to do this:
          // const history = useHistory();
          // setCurrentUser({ id: response.data.id });
          // history.push("/account");

          // but the CSRF token changes when users login, so we need to do the full page reload below
          // until we can figure out how to refresh the CSRF token
          window.location.href = "/account"; // full page reload
        }
      }
    );
  };

  return (
    <div className="flex justify-center">
      <form className="mt-12 w-96" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-3xl mb-4">Sign up for BeautifulJobs</h1>

        <label className="block mb-1 font-medium" htmlFor="firstName">
          First Name
        </label>
        <input
          id="firstName"
          className={`${
            errors.firstName ? inputErrorClassNames : inputNonErrorClassNames
          } ${inputClassNames}`}
          placeholder="Your first name"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("firstName", {
            required: true,
          })}
        />

        <label className="block mb-1 font-medium" htmlFor="lastName">
          Last Name
        </label>
        <input
          id="lastName"
          className={`${
            errors.lastName ? inputErrorClassNames : inputNonErrorClassNames
          } ${inputClassNames}`}
          placeholder="Your last name"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("lastName", {
            required: true,
          })}
        />

        <label className="block mb-1 font-medium" htmlFor="email">
          Email
        </label>
        <input
          id="email"
          className={`${
            errors.email ? inputErrorClassNames : inputNonErrorClassNames
          } ${inputClassNames}`}
          placeholder="Your email"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("email", {
            required: true,
          })}
        />
        {errors.email && errors.email.error && (
          <p className="text-red-light -mt-7 pb-0.5 pt-1.5 text-sm">
            {errors.email.error}
          </p>
        )}

        <label className="block mb-1 font-medium" htmlFor="password">
          Password
        </label>
        <input
          id="password"
          type="password"
          className={`${
            errors.password ? inputErrorClassNames : inputNonErrorClassNames
          } ${inputClassNames}`}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("password", {
            required: true,
          })}
        />
        {errors.password && errors.password.error && (
          <p className="text-red-light -mt-7 pb-0.5 pt-1.5 text-sm">
            {errors.password.error}
          </p>
        )}

        <label
          className="block mb-1 font-medium"
          htmlFor="passwordConfirmation"
        >
          Password Confirmation
        </label>
        <input
          id="passwordConfirmation"
          type="password"
          className={`${
            errors.passwordConfirmation
              ? inputErrorClassNames
              : inputNonErrorClassNames
          } ${inputClassNames}`}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("passwordConfirmation", {
            required: true,
          })}
        />
        {errors.passwordConfirmation && errors.passwordConfirmation.error && (
          <p className="text-red-light -mt-7 pb-0.5 pt-1.5 text-sm">
            {errors.passwordConfirmation.error}
          </p>
        )}

        <div className="block mb-2 font-medium">What do you want to do?</div>
        <label
          className="block mb-1 text-sm cursor-pointer"
          htmlFor="capacityJobseeker"
        >
          <input
            id="capacityJobseeker"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register("capacity", { required: true })}
            type="radio"
            value="jobseeker"
            name="capacity"
            defaultChecked
          />
          &nbsp; I&apos;m a Jobseeker &mdash; I want to{" "}
          <strong>search for jobs</strong>.
        </label>

        <label
          className="block mb-8 cursor-pointer text-sm"
          htmlFor="capacityEmployer"
        >
          <input
            id="capacityEmployer"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register("capacity", { required: true })}
            type="radio"
            value="employer"
            name="capacity"
          />
          &nbsp; I&apos;m an Employer &mdash; I want to{" "}
          <strong>post jobs</strong>.
        </label>

        <div className="mt-4 mb-4">
          <Button cta="Sign Up" isabled={loading} />
        </div>

        <p className="text-sm mb-8">
          Have an account?{" "}
          <Link className="underline" to="/login">
            Sign in
          </Link>
        </p>
      </form>
    </div>
  );
};

export default RegisterPage;
