import React from "react";
import AccountLinks from "components/AccountLinks";
import JobsTable from "components/JobsTable";
import { useQuery } from "@apollo/client";
import { JOBS } from "gql/queries/jobs.gql";
import Button from "components/Forms/Button";
import { NUM_PAGINATION_RECORDS } from "constants.js";

const UserJobsPage = () => {
  const { loading, error, data, fetchMore } = useQuery(JOBS, {
    variables: {
      offset: 0,
      limit: NUM_PAGINATION_RECORDS,
      owned: true,
    },
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div className="">
      <h1 className="text-xl font-bold">Your Jobs</h1>
      <div>
        <JobsTable jobs={data.jobs} owner />
        {data.jobs.length % NUM_PAGINATION_RECORDS === 0 && (
          <Button
            cta="Load More Jobs"
            disabled={loading}
            className="sm:w-auto sm:px-5 bg-gray-100 border border-charcoal text-charcoal"
            onClick={() => {
              fetchMore({
                variables: {
                  offset: data.jobs.length,
                },
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UserJobsPage;
