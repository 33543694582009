import { gql } from '@apollo/client'

export const BUSINESSES = gql`
  query businesses($offset: Int, $limit: Int, $search: String, $owned: Boolean) {
    businesses(offset: $offset, limit: $limit, search: $search, owned: $owned) {
      id
      name
      state
      country
      jobsCount
    },
    currentUser {
      id
      firstName
      lastName
      email
      capacity
      role
    }
  }
`
