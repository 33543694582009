import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
// import { CURRENT_USER } from "gql/queries/current_user.gql";

const Masquerade = () => {
  const location = useLocation();
  const history = useHistory();
  const [masqueradeUserObject, setMasqueradeUserObject] = useState();

  const [unmasquerade, { data }] = useMutation(
    gql`
      mutation {
        unmasquerade(input: {}) {
          success
          originalUserId
        }
      }
    `,
    {
      update(
        cache,
        {
          data: {
            unmasquerade: { success, originalUserId },
          },
        }
      ) {
        if (success) {
          cache.modify({
            fields: {
              currentUser() {
                return { __ref: `User:${originalUserId}` };
              },
            },
          });
        }
      },
      // refetchQueries: [{ query: CURRENT_USER }],
    }
  );

  const unMasquerade = () => {
    unmasquerade({
      variables: {},
    });
  };

  useEffect(() => {
    const cachedMasqueradeUserObject =
      window.localStorage.getItem("masqueradeUser");
    if (cachedMasqueradeUserObject) {
      setMasqueradeUserObject(JSON.parse(cachedMasqueradeUserObject));
    }
  }, [location.key]);

  useEffect(() => {
    if (!data) return;

    if (data.unmasquerade.success) {
      window.localStorage.removeItem("masqueradeUser");
      setMasqueradeUserObject(undefined);
      // setTimeout(function () {
      //   history.push("/admin");
      // }, 1000);
      history.push("/admin");
    }
  }, [data]);

  if (!masqueradeUserObject) return null;

  return (
    <div className="w-full flex justify-center">
      <p className="fixed bottom-4 w-full rounded bg-black bg-opacity-70 text-white w-full sm:w-3/5 text-center p-2">
        Logged in as {masqueradeUserObject.firstName}{" "}
        {masqueradeUserObject.lastName}.{" "}
        <span
          className="inline-block cursor-pointer"
          onClick={unMasquerade}
          onKeyDown={unMasquerade}
          role="button"
          tabIndex={0}
        >
          Return to own account.
        </span>
      </p>
    </div>
  );
};

export default Masquerade;
