import React from "react";
import PropTypes from "prop-types";

import { sendData } from "utils/request";

const Logout = ({ className }) => {
  const logout = () => {
    sendData("/users/sign_out", "DELETE", {}, (response) => {
      if (response instanceof Error) {
      } else {
        window.location.href = "/login"; // full page reload
      }
    });
  };

  return (
    <div
      className={`cursor-pointer text-white ${className}`}
      onClick={logout}
      onKeyPress={logout}
      role="button"
      tabIndex="0"
    >
      Logout
    </div>
  );
};

Logout.propTypes = {
  className: PropTypes.string,
};

Logout.defaultProps = {
  className: "",
};

export default Logout;
