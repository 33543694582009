import { gql } from '@apollo/client'

export const MASQUERADE_AS_USER = gql`
  mutation MasqueradeAsUser($userId: String!) {
    masqueradeAsUser(input: { userId: $userId }) {
      user {
        id,
        firstName,
        lastName,
        email,
        capacity
      }
      errors {
        path
        message
      }
    }
  }
`
