import React from "react";

const PublishErrorPage = () => {
  return (
    <div className="">
      <div className="sm:ml-52 p-4">
        <h1 className="text-xl font-bold">Error Publishing Job.</h1>
        <p className="text-sm mb-4">We couldnt publish this job.</p>
      </div>
    </div>
  );
};

export default PublishErrorPage;
