import { gql } from '@apollo/client'

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      firstName
      lastName
      email
      capacity
      role
      unreadApplicationsCount
    }
  }
`
