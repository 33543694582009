import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { sendData } from "utils/request";

import Button from "components/Forms/Button";

const RequestResetPasswordPage = () => {
  let isSubscribed = true;
  const [loading, setLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    return () => {
      isSubscribed = false;
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    sendData(
      "/users/password",
      "POST",
      { user: { email: data.email } },
      (response) => {
        if (isSubscribed) {
          setLoading(false);
          if (response instanceof Error) {
            if (response.response.data.errors.email[0] === "not found") {
              setIsReset(true);
            } else {
              const error = `Email ${response.response.data.errors.email[0]}`;
              setError("email", { type: "string", error });
            }
          } else {
            setIsReset(true);
          }
        }
      }
    );
  };

  const isEmail = (email) => {
    return /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}(\s*,?\s*)*)+$/.test(email);
  };

  return (
    <div className="flex justify-center">
      <form className="mt-12 w-96" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-3xl mb-4">Reset Your Password</h1>
        {isReset && (
          <p className="text-sm">
            A password reset confirmation email will be sent to{" "}
            <strong>{getValues("email")}</strong> if they have an account on the
            platform. Check this email and click on the link to set a new
            password.
          </p>
        )}
        {!isReset && (
          <>
            <label htmlFor="email" className="block mb-1 font-medium">
              Email address
            </label>
            <input
              className={`${
                errors.email
                  ? "border-red-light focus:border-red-light"
                  : "focus:border-charcoal"
              } w-full appearance-none py-3 px-5 mb-6 text-sm text-gray-dim border focus:outline-none`}
              placeholder="Your email"
              autoComplete="new-password"
              id="email"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register("email", { required: true, validate: isEmail })}
            />

            {errors.email && errors.email.error && (
              <p className="text-red-light -mt-7 pb-0.5 pt-1.5 text-sm">
                {errors.email.error}
              </p>
            )}

            <div className="mt-4 mb-4">
              <Button cta="Reset Password" disabled={loading || isReset} />
            </div>
            <p className="text-sm mb-8 flex justify-between">
              <span>
                Account not confimed?{" "}
                <Link className="underline" to="/resend-confirmation-code">
                  Resend confirmation email
                </Link>
                .
              </span>
            </p>
          </>
        )}
      </form>
    </div>
  );
};

export default RequestResetPasswordPage;
