import React from "react";
import ApplicationsTable from "components/ApplicationsTable";
import { useQuery } from "@apollo/client";
import { INCOMING_JOB_APPLICATIONS } from "gql/queries/incomingJobApplications.gql";
import Button from "components/Forms/Button";
import { NUM_PAGINATION_RECORDS } from "constants.js";

const UserApplicationsPage = () => {
  const { loading, error, data, fetchMore } = useQuery(
    INCOMING_JOB_APPLICATIONS,
    {
      variables: {
        offset: 0,
        limit: NUM_PAGINATION_RECORDS,
      },
    }
  );

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <h1 className="text-xl font-bold">Your Job Applications</h1>
      <div>
        <ApplicationsTable
          applications={data.incomingJobApplications}
          jobOwner
        />
        {data.incomingJobApplications.length % NUM_PAGINATION_RECORDS === 0 && (
          <Button
            cta="Load More"
            disabled={loading}
            className="sm:w-auto sm:px-5 bg-gray-100 border border-charcoal text-charcoal"
            onClick={() => {
              fetchMore({
                variables: {
                  offset: data.incomingJobApplications.length,
                },
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UserApplicationsPage;
