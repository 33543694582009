import React from "react";
import PropTypes from "prop-types";

const BusinessesTable = ({ businesses, owner }) => {
  return (
    <table className="w-full mb-4">
      <thead>
        <tr>
          <th className="table-heading">ID</th>
          <th className="table-heading">Name</th>
          {owner && (
            <>
              <th className="table-heading">Location</th>
              <th className="table-heading">Jobs</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {businesses.map((business) => {
          return (
            <tr key={business.id}>
              <td>{business.id}</td>
              <td>{business.name}</td>
              {owner && (
                <>
                  <td>
                    {business.state}, {business.country}
                  </td>
                  <td>{business.jobsCount}</td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

BusinessesTable.propTypes = {
  businesses: PropTypes.array.isRequired,
  owner: PropTypes.bool,
};

BusinessesTable.defaultProps = {
  owner: false,
};

export default BusinessesTable;
