import React from "react";
import { useQuery } from "@apollo/client";
import { JOBS } from "gql/queries/jobs.gql";
import JobsTable from "components/JobsTable";

const HomePage = () => {
  const { loading, error, data, fetchMore } = useQuery(JOBS, {
    variables: {
      offset: 0,
      limit: 5,
    },
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div className="p-4">
      Home Page
      <br />
      Latest Jobs
      <JobsTable jobs={data.jobs} />
    </div>
  );
};

export default HomePage;
