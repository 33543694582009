import { gql } from '@apollo/client'

export const CREATE_STRIPE_CHECKOUT_SESSION = gql`
  mutation CreateStripeCheckoutSession($jobSlug: String!) {
    createStripeCheckoutSession(input: { jobSlug: $jobSlug }) {
      sessionUrl
      errors { message }
    }
  }
`
