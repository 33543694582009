import React from "react";

import "./Loading.css";

const Loading = ({ classNames }) => {
  return (
    <div
      className={`loader ease-linear rounded-full border-4 border-gray-200 h-8 w-8 ${classNames}`}
    />
  );
};

export default Loading;
