import { gql } from '@apollo/client'

export const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser($firstName: String!, $lastName: String!, $password: String!, $passwordConfirmation: String!, $capacity: String!) {
    updateCurrentUser(input: { firstName: $firstName, lastName: $lastName, password: $password, passwordConfirmation: $passwordConfirmation, capacity: $capacity }) {
      user {
        id
        firstName
        lastName
        email
        capacity
      }
      errors {
        path
        message
      }
    }
  }
`
