import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { debounce } from "throttle-debounce";
import { useMutation } from "@apollo/client";
import { UPDATE_JOB_APPLICATION } from "gql/mutations/update_job_application.gql";

const NotesForm = ({ notes: _notes, applicationId }) => {
  const [notes, setNotes] = useState(_notes);

  const [mutateFunction, { data, loading, error }] = useMutation(
    UPDATE_JOB_APPLICATION
  );

  const debouncedPersist = useRef(
    debounce(1000, (notesValue) => {
      console.info("persisting", notesValue);
      mutateFunction({
        variables: {
          applicationId,
          notes: notesValue,
        },
      });
    })
  );

  const handleChange = (e) => {
    setNotes(e.target.value);
    debouncedPersist.current(e.target.value, applicationId);
    // debouncePersistNotes(e.target.value, applicationId);
  };

  return (
    <form>
      <textarea value={notes} onChange={handleChange} />
    </form>
  );
};

NotesForm.propTypes = {
  notes: PropTypes.string,
  applicationId: PropTypes.number.isRequired,
};

NotesForm.defaultProps = {
  notes: "",
};

export default NotesForm;
